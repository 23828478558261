<script>
export default {
  name: "ExistingTask",
  props: {
    task: Object,
    alertColor: {
      type: String,
      default: "secondary"
    }
  },
  computed: {
    alertClass() {
      return `alert-${this.alertColor}`;
    }
  }
}
</script>

<template>
  <div class="alert" :class="alertClass">
    {{ task.content }}
  </div>
</template>

<style scoped>

</style>